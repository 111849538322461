<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: `${page_route}${store_mode ? '' : '.view'}`,
              params: { id: id },
            }"
          />
          <span
            class="text-h6 ml-1"
            v-text="`${$route.meta.title}${id ? ` | IDM ${id}` : ''}`"
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!loading">
      <v-form ref="form_data" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'GENERAL'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12">
                    <b>
                      <i>
                        <span class="red--text">*</span>
                        Solo escribir nombre completo
                      </i>
                    </b>
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Nombre*"
                      v-model="data.name"
                      :rules="rules.textRequired"
                      type="text"
                      dense
                      counter
                      maxlength="50"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Apellido paterno*"
                      v-model="data.first_surname"
                      :rules="rules.textRequired"
                      type="text"
                      dense
                      counter
                      maxlength="25"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Apellido materno"
                      v-model="data.second_surname"
                      :rules="rules.text"
                      type="text"
                      dense
                      counter
                      maxlength="25"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-switch
                      class="mt-0"
                      label="Usuario sistema"
                      v-model="data.user.active"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      rows="2"
                      v-model="data.observation"
                      label="Observación"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'LEGAL'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Razón social"
                      v-model="data.legal_name"
                      type="text"
                      dense
                      counter
                      maxlength="100"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="RFC*"
                      v-model="data.code_tax"
                      type="text"
                      dense
                      counter
                      maxlength="13"
                      :rules="rules.code_tax_sat"
                    />
                  </v-col>
                  <v-col v-if="login.role_id == 1" cols="12" xs="12" md="4">
                    <v-switch
                      class="mt-0"
                      :label="'Validar RFC'"
                      v-model="data.validate_code_tax"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Código postal"
                      v-model="data.legal_zip"
                      type="text"
                      dense
                      counter
                      maxlength="5"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-autocomplete
                      label="Régimen fiscal"
                      v-model="data.legal_regime_id"
                      :items="legal_regimes"
                      item-value="id"
                      :item-text="(v) => v.name"
                      :loading="legal_regimes_loading"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Domicilio fiscal"
                      v-model="data.legal_address"
                      dense
                      counter
                      maxlength="150"
                      type="text"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-file-input
                      label="RFC (PDF)"
                      v-model="data.document_code_tax_0"
                      accept=".pdf"
                      show-size
                      dense
                      prepend-icon=""
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'CONTACTO'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Teléfono"
                      v-model="data.phone"
                      :rules="rules.number"
                      type="text"
                      dense
                      counter
                      maxlength="10"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Teléfono celular"
                      v-model="data.cell_phone"
                      :rules="rules.number"
                      type="text"
                      dense
                      counter
                      maxlength="10"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      :label="`Correo electrónico${
                        data.user.active ? '*' : ''
                      }`"
                      v-model="data.email"
                      :rules="
                        data.user.active ? rules.email : rules.emailNoRequired
                      "
                      type="text"
                      dense
                      counter
                      maxlength="50"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto" :disabled="login.role_id != 15">
              <v-card-title>
                <h2 class="text-caption" v-text="'BANCO'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="4">
                    <v-autocomplete
                      label="Banco"
                      v-model="data.bank_id"
                      :items="banks"
                      item-value="id"
                      :item-text="(v) => v.bank"
                      :loading="banks_loading"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Número de cuenta"
                      v-model="data.account_number"
                      type="text"
                      dense
                      counter
                      maxlength="15"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="CLABE"
                      v-model="data.clabe"
                      :rules="rules.text18"
                      type="text"
                      dense
                      counter
                      maxlength="18"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-file-input
                      label="Cuenta bancaria (PDF)"
                      v-model="data.document_bank_account_0"
                      accept=".pdf"
                      show-size
                      dense
                      prepend-icon=""
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-file-input
                      label="Valid. cuenta (PDF)"
                      v-model="data.document_account_validation_0"
                      accept=".pdf"
                      show-size
                      dense
                      prepend-icon=""
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Día de pago"
                      v-model="data.pay_day"
                      type="text"
                      :rules="rules.number"
                      dense
                      counter
                      maxlength="2"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'CONSULTA'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Monto*"
                      v-model="data.consultation_amount"
                      type="number"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-select
                      label="Nivel*"
                      v-model="data.doctor_level_id"
                      :rules="rules.required"
                      :items="doctor_levels"
                      item-value="id"
                      :item-text="(i) => i.name"
                      :loading="doctor_levels_loading"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-switch
                      class="mt-0"
                      :label="'Costo efectivo'"
                      v-model="data.effective_cost"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-switch
                      class="mt-0"
                      :label="'Confirmación de consulta'"
                      v-model="data.confirm_consultation"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-switch
                      class="mt-0"
                      :label="'Autorizacion automática de médicamentos'"
                      v-model="data.medication_auth"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4" v-if="login.role_id === 1">
                    <v-switch
                      class="mt-0"
                      :label="'Validado para alta'"
                      v-model="data.revision"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'Generar carta servicio automáticamente'"
                      v-model="data.auto_letter_generated"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-switch
                      :disabled="login.role_id != 1"
                      class="mt-0 pt-0"
                      :label="'Confirmar Servicio'"
                      v-model="data.service_confirm"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'ESPECIALIDADES'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" v-for="(v, i) in data.specialties" :key="i">
                    <v-row v-if="v.active" dense>
                      <v-col cols="12" xs="12" md="4">
                        <v-autocomplete
                          :label="`Tipo* (${i + 1})`"
                          v-model="v.specialty_type_id"
                          :rules="rules.required"
                          :items="specialty_types"
                          item-value="id"
                          :item-text="(i) => i.specialty_type"
                          :loading="specialty_types_loading"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          label="Cédula*"
                          v-model="v.license"
                          :rules="rules.required"
                          type="text"
                          dense
                          counter
                          maxlength="15"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <v-file-input
                          label="Cédula (PDF)"
                          v-model="data['document_identification_card_' + i]"
                          accept=".pdf"
                          show-size
                          dense
                          prepend-icon=""
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        md="1"
                        class="text-center"
                        align-self="center"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              text
                              icon
                              x-small
                              color="error"
                              @click="deleteSpecilty(i)"
                            >
                              <v-icon medium v-text="'mdi-close'" />
                            </v-btn>
                          </template>
                          <span v-text="`Eliminar especialidad (${i + 1})`" />
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      @click="addEspecialidad"
                      color="warning"
                      :loading="loading"
                      x-small
                      block
                      dark
                    >
                      <v-icon left v-text="'mdi-plus'" />
                      Agregar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'CONSULTORIOS'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col
                    cols="12"
                    v-for="(v, i) in data.consulting_rooms"
                    :key="i"
                  >
                    <v-row v-if="v.active">
                      <v-col cols="12">
                        <v-card class="mx-auto">
                          <v-card-title>
                            <v-row>
                              <v-col cols="12" xs="12" md="11">
                                <h2
                                  class="text-caption"
                                  v-text="`CONSULTORIO (${i + 1})`"
                                />
                              </v-col>
                              <v-col
                                cols="12"
                                xs="12"
                                md="1"
                                class="text-right"
                                align-self="center"
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      text
                                      icon
                                      x-small
                                      color="error"
                                      @click="deleteConsultingRoom(i)"
                                    >
                                      <v-icon medium v-text="'mdi-close'" />
                                    </v-btn>
                                  </template>
                                  <span
                                    v-text="`Eliminar consultorio (${i + 1})`"
                                  />
                                </v-tooltip>
                              </v-col>
                            </v-row>
                          </v-card-title>
                          <v-divider />
                          <v-card-text>
                            <v-row dense>
                              <v-col cols="12" xs="12" md="6">
                                <v-autocomplete
                                  label="Hospital*"
                                  v-model="v.provider_id"
                                  :items="providers"
                                  item-value="id"
                                  :item-text="(i) => i.trade_name"
                                  :loading="provides_loading"
                                  dense
                                  v-on:change="getProviderInfo(i)"
                                />
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <v-col cols="12" xs="12" md="3">
                                <v-text-field
                                  label="Calle"
                                  v-model="v.street"
                                  type="text"
                                  dense
                                  :disabled="v.provider_id ? true : false"
                                  counter
                                  maxlength="100"
                                />
                              </v-col>
                              <v-col cols="12" xs="12" md="3">
                                <v-text-field
                                  label="Número exterior"
                                  v-model="v.external_number"
                                  type="text"
                                  dense
                                  :disabled="v.provider_id ? true : false"
                                  counter
                                  maxlength="20"
                                />
                              </v-col>
                              <v-col cols="12" xs="12" md="3">
                                <v-text-field
                                  label="Número interior"
                                  v-model="v.internal_number"
                                  type="text"
                                  dense
                                  :disabled="v.provider_id ? true : false"
                                  counter
                                  maxlength="20"
                                />
                              </v-col>
                              <v-col cols="12" xs="12" md="3">
                                <v-text-field
                                  label="Colonia"
                                  v-model="v.suburb"
                                  type="text"
                                  dense
                                  :disabled="v.provider_id ? true : false"
                                  counter
                                  maxlength="50"
                                />
                              </v-col>
                              <v-col cols="12" xs="12" md="3">
                                <v-autocomplete
                                  label="Estado*"
                                  v-model="v.entity_id"
                                  :items="entities"
                                  item-value="id"
                                  :item-text="(i) => i.entity"
                                  :loading="entities_loading"
                                  v-on:change="getMunicipalities(i)"
                                  dense
                                  :disabled="v.provider_id ? true : false"
                                  :rules="rules.required"
                                />
                              </v-col>
                              <v-col cols="12" xs="12" md="3">
                                <v-autocomplete
                                  label="Municipio*"
                                  v-model="v.municipality_id"
                                  :items="municipalities[i]"
                                  item-value="id"
                                  :item-text="(i) => i.municipality"
                                  :loading="v.municipalities_loading"
                                  dense
                                  :disabled="v.provider_id ? true : false"
                                  :rules="rules.required"
                                />
                              </v-col>
                              <v-col cols="12" xs="12" md="3">
                                <v-text-field
                                  label="Código postal"
                                  v-model="v.zip"
                                  :rules="rules.number"
                                  type="text"
                                  dense
                                  :disabled="v.provider_id ? true : false"
                                  counter
                                  maxlength="5"
                                />
                              </v-col>
                              <v-col cols="12" xs="12" md="3">
                                <v-file-input
                                  label="Comp. domicilio (PDF)"
                                  v-model="data['document_address_' + i]"
                                  accept=".pdf"
                                  show-size
                                  dense
                                  prepend-icon=""
                                />
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      @click="addConsultorio"
                      color="warning"
                      :loading="loading"
                      x-small
                      block
                      dark
                    >
                      <v-icon left v-text="'mdi-plus'" />
                      Agregar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'REDES'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col
                    cols="12"
                    v-for="(doctor_in_group, i) in data.doctor_in_groups"
                    :key="i"
                  >
                    <v-row v-if="doctor_in_group.active">
                      <v-col cols="12" xs="12" md="10">
                        <v-autocomplete
                          :label="`Nombre* (${i + 1})`"
                          v-model="doctor_in_group.doctor_group_id"
                          :rules="rules.required"
                          :items="doctor_groups"
                          item-value="id"
                          :item-text="(i) => i.doctor_group"
                          :loading="doctor_groups_loading"
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        md="2"
                        class="text-center"
                        align-self="center"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              text
                              icon
                              x-small
                              color="error"
                              @click="deleteDoctorGroup(i)"
                            >
                              <v-icon medium v-text="'mdi-close'" />
                            </v-btn>
                          </template>
                          <span v-text="`Eliminar red (${i + 1})`" />
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      @click="addDoctorGroup"
                      color="warning"
                      :loading="loading"
                      x-small
                      block
                      dark
                    >
                      <v-icon left v-text="'mdi-plus'" />
                      Agregar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" xs="12" class="text-right">
            <BtnCircle
              :tLabel="store_mode ? 'Crear' : 'Editar'"
              tPosition="top"
              :icon="store_mode ? 'mdi-plus' : 'mdi-pencil'"
              :color="store_mode ? 'success' : 'info'"
              size="small"
              :click="handleSubmit"
              :loading="loading"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
} from "../../control";
import { catalog } from "../../requests/catalogRequest";
import { storeUpdate, show, index } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import DatePicker from "../../components/DatePicker.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    DatePicker,
    FaqDlg,
  },
  data() {
    return {
      page_route: "doctors",
      id: this.$route.params.id ? this.$route.params.id : 0,
      store_mode: this.$route.params.id ? false : true,
      login: this.$store.getters.getLogin,
      loading: false,
      data: {
        id: null,
        name: "",
        first_surname: "",
        second_surname: "",
        legal_name: "",
        code_tax: "",
        legal_address: "",
        phone: "",
        cell_phone: "",
        email: "",
        consultation_amount: "0",
        doctor_level_id: null,
        effective_cost: false,
        specialties: [],
        consulting_rooms: [],
        medication_auth: false,
        confirm_consultation: true,
        bank_id: null,
        account_number: "",
        clabe: "",
        pay_day: "3",
        user_id: null,
        user: {
          id: null,
          active: false,
          email: null,
        },
        legal_zip: "",
        legal_regime_id: null,
        doctor_in_groups: [],
        bank_account_pdf: null,
        account_validation_pdf: null,
        validate_code_tax: true,
        auto_letter_generated: false,
        service_confirm: true,
      },
      rules: rules(),
      municipalities: [],
      entities: [],
      entities_loading: true,
      specialty_types: [],
      specialty_types_loading: true,
      doctor_levels: [],
      doctor_levels_loading: true,
      providers: [],
      provides_loading: true,
      banks: [],
      banks_loading: true,
      legal_regimes: [],
      legal_regimes_loading: true,
      doctor_groups: [],
      doctor_groups_loading: true,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    select_file(event, i, file) {
      let position = "document_" + file + "_" + i;
      this.data[position] = event.target.files[0];
    },
    getMunicipalities(i) {
      this.data.consulting_rooms[i].municipalities_loading = true;

      index(
        "municipalities/" + this.data.consulting_rooms[i].entity_id,
        this.login.token,
        [
          {
            name: "active",
            value: 1,
          },
          {
            name: "id",
            value: this.login.id,
          },
        ]
      ).then((response) => {
        this.municipalities[i] = response.data;
        this.data.consulting_rooms[i].municipalities_loading = false;
        this.$forceUpdate();
      });
    },
    handleSubmit() {
      this.data.clabe = this.data.clabe == null ? "" : this.data.clabe;
      this.data.account_number =
        this.data.account_number == null ? "" : this.data.account_number;

      if (
        (this.data.clabe == "" && this.data.account_number == "") ||
        ((this.data.clabe != "" || this.data.account_number != "") &&
          this.data.bank_id != null)
      ) {
        if (this.$refs.form_data.validate()) {
          this.$swal
            .fire(
              msgConfirm(
                `¿Confirma la ${
                  this.store_mode ? "creación" : "edición"
                } del registro?`
              )
            )
            .then((response) => {
              if (response.isConfirmed) {
                this.loading = true;

                if (this.data.user.active) {
                  this.data.user.email = this.data.email;
                }

                let obj = Object.assign({}, this.data);
                obj.user = JSON.stringify(obj.user);

                storeUpdate(
                  this.page_route,
                  this.login.token,
                  this.id,
                  obj,
                  this.login.id
                ).then((response) => {
                  this.$swal.fire(
                    msgAlert(
                      response.success ? "success" : "error",
                      response.message
                    )
                  );

                  response.success
                    ? this.$router.push({
                        name: `${this.page_route}${
                          this.store_mode ? "" : ".view"
                        }`,
                        params: { id: this.id },
                      })
                    : console.log(response.message);
                });

                this.loading = false;
              }
            });
        } else {
          this.$swal.fire(
            msgAlert(
              "error",
              "Formulario invalido, revisa los detalles señalados"
            )
          );
        }
      } else {
        this.$swal.fire(
          msgAlert("error", "El campo de banco debe ser seleccionado")
        );
      }
    },
    addEspecialidad() {
      this.data.specialties.push({
        id: null,
        license: "",
        specialty_type_id: null,
        active: true,
      });
    },
    getProviderInfo(i) {
      this.data.consulting_rooms[i].street = "";
      this.data.consulting_rooms[i].external_number = "";
      this.data.consulting_rooms[i].internal_number = "";
      this.data.consulting_rooms[i].suburb = "";
      this.data.consulting_rooms[i].zip = "";
      this.data.consulting_rooms[i].entity_id = null;
      this.data.consulting_rooms[i].municipality_id = null;
      this.municipalities[i] = [];
      this.data.consulting_rooms[i].municipalities_loading = false;

      if (this.data.consulting_rooms[i].provider_id) {
        show(
          "providers",
          this.login.token,
          this.data.consulting_rooms[i].provider_id
        ).then((res) => {
          this.data.consulting_rooms[i].street = res.street;
          this.data.consulting_rooms[i].external_number = res.external_number;
          this.data.consulting_rooms[i].internal_number = res.internal_number;
          this.data.consulting_rooms[i].suburb = res.suburb;
          this.data.consulting_rooms[i].zip = res.zip;
          this.data.consulting_rooms[i].entity_id = res.entity_id;
          this.getMunicipalities(i);
          this.data.consulting_rooms[i].municipality_id = res.municipality_id;
        });
      }
    },
    addConsultorio() {
      this.municipalities.push = [];
      this.data.consulting_rooms.push({
        id: null,
        street: "",
        external_number: "",
        internal_number: "",
        suburb: "",
        municipality_id: null,
        city: "",
        zip: "",
        start: null,
        end: null,
        entity_id: null,
        active: true,
        municipalities_loading: false,
        provider_id: null,
      });
    },
    deleteSpecilty(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar la especialidad (${i + 1})?`))
        .then((response) => {
          if (response.isConfirmed) {
            if (this.data.specialties[i].id === null)
              this.data.specialties.splice(i, 1);
            else this.data.specialties[i].active = false;
          }
        });
    },
    deleteConsultingRoom(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar el consultorio (${i + 1})?`))
        .then((response) => {
          if (response.isConfirmed) {
            if (this.data.consulting_rooms[i].id === null)
              this.data.consulting_rooms.splice(i, 1);
            else this.data.consulting_rooms[i].active = false;
          }
        });
    },
    addDoctorGroup() {
      this.data.doctor_in_groups.push({
        id: null,
        active: true,
        doctor_group_id: null,
      });
    },
    deleteDoctorGroup(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar la red (${i + 1})?`))
        .then((response) => {
          if (response.isConfirmed) {
            if (this.data.doctor_in_groups[i].id === null)
              this.data.doctor_in_groups.splice(i, 1);
            else this.data.doctor_in_groups[i].active = false;
          }
        });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    catalog("entities", this.login.token).then((response) => {
      this.entities = response;
      this.entities_loading = false;
    });
    catalog("specialty_types", this.login.token).then((response) => {
      this.specialty_types = response;
      this.specialty_types_loading = false;
    });
    catalog("doctor_levels", this.login.token).then((response) => {
      this.doctor_levels = response;
      this.doctor_levels_loading = false;
    });
    catalog("banks", this.login.token).then((response) => {
      this.banks = response;
      this.banks.push({
        id: null,
        bank: "PENDIENTE",
      });
      this.banks_loading = false;
    });
    index("doctor/groups", this.login.token, [
      {
        name: "active",
        value: 1,
      },
      {
        name: "id",
        value: this.login.id,
      },
    ]).then((response) => {
      this.doctor_groups = response.data;
      this.doctor_groups_loading = false;
    });

    this.legal_regimes = [
      {
        id: 1,
        name: "GENERAL DE LEY PERSONAS MORALES",
      },
      {
        id: 2,
        name: "PERSONAS FÍSICAS CON ACTIVIDADES EMPRESARIALES Y PROFESIONALES",
      },
      {
        id: 3,
        name: "RÉGIMEN SIMPLIFICADO DE CONFIANZA",
      },
    ];
    this.legal_regimes.push({
      id: null,
      name: "PENDIENTE",
    });
    this.legal_regimes_loading = false;

    show("providers/type", this.login.token, 4).then((response) => {
      this.providers = response;
      this.providers.push({
        id: null,
        trade_name: "*NINGUNO*",
      });
      this.provides_loading = false;
    });

    if (!this.store_mode) {
      this.loading = true;

      show(this.page_route, this.login.token, this.id).then((response) => {
        this.data = response;
        this.loading = false;
        for (var i = 0; i < this.data.consulting_rooms.length; i++) {
          this.getMunicipalities(i);
        }
      });
    }
  },
};
</script>